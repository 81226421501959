/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import SchemaOrg from './SchemaOrg'

function SEO({
  description,
  lang,
  meta,
  keywords,
  image,
  title,
  // highlight start
  pathname,
  isBlogPost,
  author,
  siteUrl,
  organization,
  datePublished = false,
  dateModified = false
  // highlight-end
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  const siteMetadata =site.siteMetadata;
  const metaDescription = description || siteMetadata.description
  const metaUrl = description || site.siteMetadata.url
  const metaImage = description || site.siteMetadata.image
  author = author ? author : {name: siteMetadata.author,email:siteMetadata.email}
 

  return (
    <>{/* Fragment Shorthand */}
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: isBlogPost ? `article` : `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        // ALWAYS ADD IMAGE:ALT
        {
          property: "og:image:alt",
          content: image ? image.alt : ''
        },
        {
          property: "twitter:image:alt",
          content: image ? image.alt : ''
        },
      ].concat(meta)
      .concat(
        // handle Secure Image
        metaImage && metaImage.indexOf("https") > -1
          ? [
              {
                property: "twitter:image:secure_url",
                content: metaImage,
              },
              { property: "og:image:secure_url", content: metaImage },
            ]
          : []
      )}
    />

    <SchemaOrg
    isBlogPost={isBlogPost}
    url={metaUrl}
    title={title}
    image={metaImage}
    description={metaDescription}
    datePublished={datePublished}
    dateModified={dateModified}
    canonicalUrl={siteUrl}
    author={author}
    organization={organization}
    defaultTitle={title}
  />
  </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
